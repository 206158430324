/* eslint-disable react/prop-types */
import React from "react";
import AppContextProvider from "./AppContext";

interface IRootElementProps {
  children: React.ReactNode;
}

const RootElement: React.FC<IRootElementProps> = ({ children }) => {
  return <AppContextProvider>{children}</AppContextProvider>;
};

export default RootElement;
