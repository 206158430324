/* eslint-disable react/prop-types */
import React from 'react';
import { createContext, useState } from "react";

export const AppContext = createContext<{
  mobileNavIsOpen: boolean;
  changeMobileNavState: (value: boolean) => void;
    }>({
      mobileNavIsOpen: false,
      changeMobileNavState: () => {}
    });

interface IAppContextProviderProps {
  children: React.ReactNode;
}

const AppContextProvider: React.FC<IAppContextProviderProps> = ({
  children,
}) => {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);

  return (
    <AppContext.Provider
      value={{
        mobileNavIsOpen: mobileNavIsOpen,
        changeMobileNavState: (value: boolean) => setMobileNavIsOpen(value),
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
